<template>
  <div class="login-container">
    <div class="selBox">
      <div class="boxContaner">
        <div class="title">请选择操作的测评及考点</div>
        <div class="twoSel">
          <div class="selItem">
            <div class="selLabel">测评</div>
            <el-select class="theSelect" v-model="value1" @change="changeList">
              <el-option
                v-for="(item, i) in assessmentList"
                :label="item.name"
                :value="item.id"
                :key='i'
              >
              </el-option>
            </el-select>
          </div>
          <div class="selItem">
            <div class="selLabel">考点</div>
            <el-select class="theSelect" v-model="value2" @change="changeList2">
              <el-option
                v-for="item in options2"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            :loading="loading"
            type="primary"
            style="width: 100%; margin-top: 20px"
            @click.native.prevent="next"
            >进入</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
		mapState,
		mapMutations
	} from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      assessmentList: [],
      value1: '',
      options2: [],
      value2: '',
      testName: '',
      sitesName: '',
      testEndTime:'',
      testStartTime:''
    }
  },
  watch: {

  },
  methods: {
    changeList(e) {
      for (var i in this.assessmentList) {
        if (this.assessmentList[i].id == e) {
          this.testName = this.assessmentList[i].name;
          this.moduleName = this.assessmentList[i].moduleName;
          this.testEndTime = this.assessmentList[i].testEndTime;
          this.testStartTime = this.assessmentList[i].testStartTime;
          this.options2 = this.assessmentList[i].assessmentSitesList;
          this.value2 = this.assessmentList[i].assessmentSitesList[0].id;
          this.sitesName = this.assessmentList[i].assessmentSitesList[0].name;
        }
      }
    },
    changeList2(e) {
      for (var i in this.options2) {
        if (this.options2[i].id == e) {
          this.sitesName = this.options2[i].name;
        }
      }
    },
    next() {
      let testInfo={
        testId : this.value1,
        sitesId:this.value2,
        testName:this.testName,
        sitesName:this.sitesName,
        testEndTime:this.testEndTime,
        testStartTime:this.testStartTime,
        moduleName:this.moduleName
      }
      this.upDateTestInfo(testInfo);
      this.$router.push({ path: '/' })
    },
    ...mapMutations(['upDateTestInfo'])
  },
  mounted() {
    this.assessmentList = this.$storage.getStorage('assessmentList');
    this.value1 = this.assessmentList[0].id;
    this.testName = this.assessmentList[0].name;
    this.testEndTime = this.assessmentList[0].testEndTime;
    this.testStartTime = this.assessmentList[0].testStartTime;
    this.moduleName = this.assessmentList[0].moduleName;
    this.options2 = this.assessmentList[0].assessmentSitesList;
    this.value2 = this.options2[0].id;
    this.sitesName = this.options2[0].name;
  }
}
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
.login-container {
  min-height: 100%;
  width: 100%;
  background: $bg;
  overflow: hidden;
  .selBox {
    width: 505px;
    position: relative;
    margin: 0 auto;
    padding: 160px 35px 0;
    .boxContaner {
      width: 435px;
      padding: 30px 0;
      background: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 18px;
        font-weight: 700;
        color: #333;
      }
      .twoSel {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .selItem {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          border: 1px solid #eeeeed;
          .selLabel {
            line-height: 25px;
            width: 70px;
            text-align: center;
            border-right: 1px solid #eeeeed;
          }
          .theSelect {
            flex: 1;
            /deep/ input {
              border: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
